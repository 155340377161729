import { API } from "../api";
import { UtilsString } from "@/utils/utils-string";
import { ssmHttpService } from "./http-service";
import { ssmTokenService } from "./token-service";
//import { ssmSignalRService } from './signal-r-service';
import { Login } from "../dtos/login";
import { medicalUserService } from "./user-service";

export default class AuthorizationService {
  public userName() {
    return medicalUserService.getuser();
  }

  public login(login: Login): Promise<Login> {
    // return Promise.resolve(this.onLogin({Success: true, Name: login.UserName, Token: 'myTokenHashed'}));

    // Nota: en una aplicación real esta línea debe llamar al API para validar el usuario
    return ssmHttpService
      .post(API.login, login.toJson())
      .then(this.onLogin.bind(this))
      .catch(this.loginFail.bind(this));
  }

  public logout() {
    medicalUserService.setuser(UtilsString.Empty);
    // Nota: descomentar esta línea si se va a usar SignalR para recibir eventos del servidor
    // ssmSignalRService.leaveChannel();
    ssmTokenService.clearToken();
    ssmHttpService.updateTokenHeader();
  }

  public onLogin(res: any) {
    if (res.Success) {
      var login = new Login(res);
      if (!login.chg_pwd && !login.doble_factor) {
        medicalUserService.setuser(login.Name);
        ssmTokenService.setToken(login.Token);
        ssmHttpService.updateTokenHeader();
      } else {
        medicalUserService.clearuser();
        ssmTokenService.clearToken();
        ssmHttpService.updateTokenHeader();
      }

      //ssmSignalRService.setWebChannelHubBase((process.env.VUE_APP_ROOT_CHANNELHUB as string))
      //await ssmSignalRService.setWebChannelHubBase('https://localhost:44352/api/question-hub')
      // Nota: descomentar esta línea si se va a usar SignalR para recibir eventos del servidor
      // ssmSignalRService.joinChannel();

      return new Login(res);
      //return new Login({ Success: true, chg_pwd: res.chg_pwd });
    } else {
      medicalUserService.clearuser();
      ssmTokenService.clearToken();
      ssmHttpService.updateTokenHeader();
      return new Login({ Success: false, chg_pwd: false });
    }
  }

  private loginFail(res: any) {
    return new Login({ Success: false, chg_pwd: false });
  }

  public generateQr(login: Login) {
    return ssmHttpService.get(
      API.login + `/generate-qr?email=${login.UserName}`,
      login.toJson()
    );
  }

  public validarOtp({
    secretKey,
    otp,
    UserName,
    Pwd,
  }: {
    secretKey: string;
    otp: string;
    UserName: string;
    Pwd: string;
  }) {
    return ssmHttpService.post(API.login + `/validate-otp`, {
      secretKey: secretKey,
      otp: otp,
      UserName: UserName,
      Pwd: Pwd,
    });
  }
}

export const authorizationService = new AuthorizationService();
